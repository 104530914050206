<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css">
  .fee-standard {
    overflow-y: auto;
  }

  .fee-standard .position-item {
    height: 26px;
    padding-left: 10px;
  }

  .fee-standard .form-check-input {
    margin-bottom: 5px;
  }

  .pay .form-check-input {
    margin-top: unset;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import ExamHeader from "./exam-header";
  import {
    editExamRecord,
    getExamDetails
  } from "@/api/admin/exam/examRecord.js"
  import {
    getSubListEasy
  } from "@/api/admin/base/subject.js"
  import {
    getDicts
  } from "@/api/common/dict.js";
  import YzSelect from "@/components/form/yzSelect.vue";
  import {
    getListPage
  } from "@/api/admin/base/position.js"
  import {
    createKsbmb,
    hasTable,
    removeBmk
  } from "@/api/admin/exam/createExamku.js"
  /**
   * 考试报名--收费
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ExamHeader,
      YzSelect


    },
    data() {
      return {
        title: "考试报名信息管理 /",
        title2: "",
        ksmc: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "考试费用",
            active: true
          }
        ],
        checked: true,
        sid: "",
        ksbmbh: "",
        flbm: "",
        dict: {},
        sffs: [],
        sfbz: [],
        isCreateBmk: false,
        positionList: [],
        examForm: {
          ksbmbh: "",
          sfxyjf: "",
          jffs: 0,
          sfbz: "",
          sfzhid: "",
          sfzhmc: "",
          sffssz: "",
          sfyxdwjzjf: "",
          sfyxxxjf: "",
          sfkqxstf: "",
          sfkqdzfp: "",
        },
        classList: [],
        showTips:false,
      };
    },
    methods: {
      editExamForm() {
        if (this.isCreateBmk) {
          let formDate = JSON.parse(JSON.stringify(this.examForm))
          formDate.sfzhid = this.examForm.shbm
          formDate.sfzhmc = this.examForm.shmc
          formDate.sffssz = [0, 0, 0, 0]
          this.sffs.forEach((item) => {
            formDate.sffssz[item - 1] = 1
          })
          formDate.sffssz = formDate.sffssz.join('')
          formDate.sfbz = formDate.sfbz * 100
          if (this.examForm.jffs == 0) {
            if (this.examForm.sfbz > 0) {
              formDate.sfbz = this.examForm.sfbz
            } else {
              this.$message({
                type: 'warning',
                message: '请填写大于0单科收费标准!'
              });
              return
            }
          }
          editExamRecord(formDate).then(res => {
            if (res.status) {
              this.success()
              formDate.sfbz = formDate.sfbz / 100
            }
          })

        } else {
          this.$message({
            type: 'warning',
            message: '请务必先生成报名库!'
          });
        }

      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据保存成功!',
          duration: 3000,
        })
      },
      getDetail(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          if (res.status) {
            this.examForm = res.data
            this.flbm = res.data.flbm
            this.examForm.sfbz = this.examForm.sfbz / 100
            this.examForm.shbm = res.data.sfzhid
            this.examForm.shmc = res.data.sfzhmc
            this.ksmc = res.data.ksmc ? res.data.ksmc : ""
            if (this.flbm) {
              this.getSubListEasy()
            }
            if (this.examForm.jfsjzt == false) {
              this.$notify({
                title: '警告',
                message: '此页面涉及到缴费，请先确定是否已开启缴费状态，并设置缴费时间',
                type: 'warning'
              });
              this.showTips=true
            }  else{
              this.showTips=false

            }
            if (this.examForm.sffssz) {
              this.sffs = this.examForm.sffssz.split('')
              this.sffs.forEach((item, index) => {
                if (item != '0') {
                  this.sffs[index] = index + 1
                }
              })
            }
            if (this.examForm.bmkbm) {
              this.isCreateBmk = true
            }

          }
        })
      },

      // 获取科目
      getSubListEasy() {
        getSubListEasy({
          flbm: this.flbm
        }).then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },

      //获取职位
      getPostion() {
        getListPage({
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.positionList = res.data
          }
        })
      },
      // 生成考试报名库
      addKsbmk() {
        this.$confirm('此操作将生成报名库,请确认是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            createKsbmb({
              ksbmbh: this.ksbmbh
            }).then(res => {
              console.log(res)
              if (res.status) {
                // this.examForm.bmkbm = res.message
                this.$message({
                  type: 'success',
                  message: res.message
                });
                this.isCreateBmk = true
                if (res.message.includes('无需创建报名表')) {
                  this.editExamForm()
                }
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消生成报名库'
            });
          });
      },
      hasKsbmk() {
        // 先判断是否存在该报名库
        if (this.examForm.bmkbm) {
          this.removeBmk()
        } else {
          this.addKsbmk()
        }

      },

      removeBmk() {
        hasTable(this.ksbmbh).then(res => {
          if (res.status) {
            let existsTable = res.data
            if (existsTable.exists) {
              let tabName = existsTable.tableName
              let rowCount = existsTable.rowCount
              let message = "数据库中已存在表名为" + tabName + "的数据表，该表已有" + rowCount +
                "条数据，是否确定删除当前表中所有数据，并重新生成报名表，是否继续?"
              this.$confirm(message, '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                removeBmk(this.ksbmbh).then(res => {
                  if (res.status) {
                    this.$message({
                      type: 'success',
                      message: '删除成功!'
                    });
                    this.addKsbmk()
                  }
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                });
                return;
              });
            }
          }
        })
      }
    },
    mounted() {
      if (this.ksbmbh) {
        this.getDetail(this.ksbmbh);
      }
      getDicts("sh").then((res) => {
        this.dict = res;
      });
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : ""
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :items="items" />
    <ExamHeader />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <form action="" ref="examForm">
              <div class="outerCotent mt-3 ">
                <input type="hidden" class=" msgValInput w-30" value="" name="sid">
                <div class="msgContent flexList fs-xs">
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgName ">是否需要缴费
                    </div>
                    <div class="msgVal selectShow flexList">
                      <b-form-checkbox v-model="examForm.sfxyjf" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel ">
                    <div class="msgName ">缴费方式</div>
                    <div class="msgVal selectShow  flexList w-33">
                      <div class="frame-wrap">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input" id="value1" value="0"
                            v-model="examForm.jffs">
                          <label class="custom-control-label" style="line-height:19px" for="value1">按单次报名收费标准
                          </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline" v-if="examForm.kmxzfs==2">
                          <input type="radio" class="custom-control-input " id="value2" value="1"
                            v-model="examForm.jffs">
                          <label class="custom-control-label" style="line-height:19px" for="value2">按报考职位收费</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline" v-if="examForm.kmxzfs==1">
                          <input type="radio" class="custom-control-input " id="value3" value="2"
                            v-model="examForm.jffs">
                          <label class="custom-control-label" style="line-height:19px" for="value3">按考试科目收费</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100">
                    <div class="flexList w-100 msgLabel" v-if="examForm.jffs==0">
                      <div class="msgName ">单次报名收费标准</div>
                      <div class="msgVal selectShow  flexList">
                        <b-form-input id="number" value="1" type="number" name="number" v-model="examForm.sfbz"
                          class="w-30 h30">
                        </b-form-input>
                      </div>
                    </div>
                    <div class="flexList w-100 msgLabel" style="height: 110px;" v-else>
                      <div class="msgName ">设置收费标准</div>
                      <div class="msgVal  fee-standard">
                        <div v-if="examForm.jffs==1">
                          <div class="position-item flexList" v-for="(item,index) in positionList" :key="index">
                            {{item.zwmc}} {{item.sfbz/100}}元
                          </div>
                        </div>
                        <div v-if="examForm.jffs==2">
                          <div class="position-item flexList " v-for="(item,index) in classList" :key="index">
                            {{item.kmmc}}
                            {{item.sfbz/100}}元/科
                          </div>
                          <div class="flexList h30"><i
                              class="iconfont icon-question-circle font-size-18"></i>考试科目及收费标准设置请<a
                              href="/admin/baseMajors" class="text-info">点击进入</a>科目设置</div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">收费账户设置</div>
                    <div class="msgVal selectShow  flexList">
                      <yz-select style="width: 30%" :dict="dict.sh" :bound="['shbm', 'shmc']" v-model="examForm">
                      </yz-select>
                      如果使用合利宝，请用带有“合利宝”后缀的账户
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">收费方式设置</div>
                    <div class="msgVal  flexList pay">
                      <div class="form-check flexList mr-2"><input type="checkbox" value="1" v-model="sffs"
                          id="defaultCheck1" class="form-check-input"><label for="defaultCheck1"
                          class="form-check-label">微信支付
                        </label>
                      </div>
                      <div class="form-check flexList mr-2"><input type="checkbox" value="2" v-model="sffs"
                          id="defaultCheck2" class="form-check-input"><label for="defaultCheck2"
                          class="form-check-label">支付宝
                        </label>
                      </div>
                      <div class="form-check flexList mr-2"><input type="checkbox" value="3" v-model="sffs"
                          id="defaultCheck3" class="form-check-input"><label for="defaultCheck3"
                          class="form-check-label">易宝
                        </label>
                      </div>
                      <div class="form-check flexList mr-2"><input type="checkbox" value="4" v-model="sffs"
                          id="defaultCheck3" class="form-check-input"><label for="defaultCheck3"
                          class="form-check-label">合利宝
                        </label>
                      </div>
                    <span v-if="showTips" class="text-danger ml-2">当前还未设置缴费时间，会影响后续考试报名，请先设置</span>
                    </div>
                  </div>

                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">是否允许单位集中缴费</div>
                    <div class="msgVal  flexList">
                      <b-form-checkbox v-model="examForm.sfyxdwjzjf" switch class="mt-3 switch-check">
                      </b-form-checkbox>

                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">是否允许线下缴费</div>
                    <div class="msgVal  flexList">
                      <b-form-checkbox v-model="examForm.sfyxxxjf" switch class="mt-3 switch-check">
                      </b-form-checkbox>

                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">是否开启线上退费</div>
                    <div class="msgVal  flexList">
                      <b-form-checkbox v-model="examForm.sfkqxstf" switch class="mt-3 switch-check">
                      </b-form-checkbox>

                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">是否开启电子发票</div>
                    <div class="msgVal  flexList">
                      <b-form-checkbox v-model="examForm.sfkqdzfp" switch class="mt-3 switch-check">
                      </b-form-checkbox>

                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName">报名库生成</div>
                    <div class="msgVal flexList">
                      <button type="button" class="btn btn-info h30 mr-2" @click="hasKsbmk">
                        <span v-if="!examForm.bmkbm">
                          生成报名库
                        </span>
                        <span v-else>重新生成报名库</span>
                      </button>
                      <!-- <button class="btn btn-d h35 mr-2" type="button" v-else @click="remove">删除原有报名库并重新生成报名库</button> -->
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel" style="height: 60px;">
                    <div class="msgName"></div>
                    <div class="msgVal flexList">
                      <button class="btn btn-info h35 mr-2 sub-btn" type="button"
                        @click="editExamForm">保存信息，完成考试发布</button>
                    </div>
                  </div>
                </div>

              </div>
            </form>


          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
